<template>
  <div>
    <p-button
      :loading.prop="loading"
      :disabled.prop="disabled"
      :loading-success.prop="loadingSuccess"
      @click="showPositionerModal = true"
      >{{ label }}</p-button
    >
    <p-modal-plain
      :show.prop="true"
      v-if="showPositionerModal"
      :headline="title ?? label"
      @close-request="showPositionerModal = !showPositionerModal"
      has-footer
    >
      <p-container gap-size="none">
        <p-row justify-content="center">
          <p-paragraph> Select a field to scale or adjust the size and position of the field </p-paragraph>
        </p-row>
        <p-row justify-content="center">
          <p-paragraph>
            You can drag the fields around or use the input and arrow keys for more precise adjustments.
          </p-paragraph>
        </p-row>
      </p-container>

      <div class="element-positioner">
        <div class="element-positioner__container">
          <div class="positioner" ref="parentContainer">
            <div class="positioner__inner" ref="container">
              <img v-if="image" ref="imageElement" :src="image" :draggable="false" />
              <div
                v-if="canvas"
                ref="canvasRef"
                class="positioner__canvas"
                :style="{ width: canvasWidth + 'px', height: canvasHeight + 'px' }"
              ></div>

              <div v-if="showingModal">
                <div
                  class="positioner__element"
                  :class="selectedIndex === index ? 'positioner__element--selected' : ''"
                  v-for="(item, index) in items"
                  :data-id="item.id"
                  :key="item.id"
                  :style="{
                    ...item.style
                  }"
                  @mousedown="onMoveMoveStart($event, index)"
                  @mousemove="onMoveMove"
                  @mouseup="onMoveEnd"
                >
                  <div class="positioner__element-resize" @mousedown="onMouseResizeStart($event, index)"></div>
                  <div v-if="item.label && !toggleLabels" class="positioner__element-label">{{ item.label }}</div>
                  <div v-else-if="label && !toggleLabels" class="positioner__element-label">{{ label }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="element-positioner__inputs">
          <p-container>
            <p-form-element label="Automatic positioning">
              <p-select @select="onPositionSelect">
                <p-select-option
                  v-for="option in options"
                  :key="option.value"
                  :value="option.value"
                  :selected.prop="position === option.value"
                  >{{ option.text }}</p-select-option
                >
              </p-select>
            </p-form-element>
            <p-form-input
              v-if="position !== 'grid'"
              ref="inputX"
              :disabled="selectedIndex === null"
              v-model="selectedItem.x"
              type="number"
              :max="maxX"
              :min="0"
              label="Horizontal placement"
              size="small"
            />

            <p-form-input
              v-if="position !== 'grid'"
              ref="inputY"
              :disabled="selectedIndex === null"
              v-model="selectedItem.y"
              type="number"
              :max="maxY"
              :min="0"
              label="Vertical placement"
              size="small"
            />

            <p-form-input
              v-if="position === 'grid'"
              v-model="maxColumnsPerRow"
              type="number"
              :min="0"
              :max="100"
              label="Max columns per row"
              size="small"
            />

            <p-form-input
              v-if="position === 'grid'"
              v-model="gridHorizontalGap"
              type="number"
              :min="0"
              :max="1000"
              label="Horizontal gap"
              size="small"
            />

            <p-form-input
              v-if="position === 'grid'"
              v-model="gridVerticalGap"
              type="number"
              :min="0"
              :max="1000"
              label="Vertical gap"
              size="small"
            />

            <p-divider />
            <p-form-input
              v-model="selectedItem.w"
              :disabled="selectedIndex === null"
              type="number"
              label="Width"
              size="small"
            />
            <p-form-input
              v-model="selectedItem.h"
              :disabled="selectedIndex === null"
              type="number"
              label="Height"
              size="small"
            />
            <p-form-checkbox
              v-model="useSameSize"
              :disabled="selectedIndex === null || position === 'grid'"
              label="Use same size"
            />

            <p-form-checkbox v-model="toggleLabels" label="Hide labels" />
          </p-container>
        </div>
      </div>

      <p-button slot="footer" color-type="tertiary" size="medium" @click="cancel()">Cancel</p-button>
      <p-button
        color-type="primary"
        @click="save()"
        :disabled.prop="loading"
        :loading.prop="loading || loadingSuccess"
        :loading-success.prop="loadingSuccess"
        size="medium"
        slot="footer"
      >
        Save
      </p-button>
    </p-modal-plain>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PropType } from 'vue';

export interface PositionerItem {
  w: number | string;
  x: number | string;
  y: number | string;
  h: number | string;
  id: number;
  label?: string;
}

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: Array as PropType<PositionerItem[]>, required: true }) public readonly modelValue?: PositionerItem[];
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly loading!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly loadingSuccess!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly canvas!: boolean;
  @Prop({ type: Number, required: false, default: null }) public readonly canvasWidth!: number | null;
  @Prop({ type: Number, required: false, default: null }) public readonly canvasHeight!: number | null;
  @Prop({ type: Number, required: false, default: null }) public readonly borderRadius!: number | null;
  @Prop({ type: String, required: false }) public readonly title!: string;
  @Prop({ type: String, required: false }) public readonly image!: string;
  @Prop({ type: String, required: false }) public readonly label!: string;

  $refs!: {
    [key: string]: Vue | Element | Vue[] | Element[];
  };

  private localModelValue: PositionerItem[] = [];

  public position = 'default';
  public options = [
    { text: 'Default', value: 'default' },
    { text: 'Grid', value: 'grid' },
    { text: 'Random', value: 'random' } // Header example
  ];

  public useSameSize = false;

  public toggleLabels = false;

  public resizing = false;
  public resizeStartX = 0;
  public resizeStartY = 0;

  public gridVerticalGap = 10;
  public gridHorizontalGap = 10;
  public maxColumnsPerRow = 6;

  public showingModal = false;

  public initialXOffset = 0;
  public initialYOffset = 0;

  public showPositionerModal = false;
  public selectedItem: PositionerItem = { w: '', x: '', y: '', h: '', id: -1 };

  private dragging = false;

  // Keep track of selected item's index or ID
  private selectedIndex: number | null = null;

  get items() {
    return this.localModelValue.map((item) => {
      return {
        ...item,
        style: {
          width: `${item.w}px`,
          height: `${item.h}px`,
          left: `${item.x}px`,
          top: `${item.y}px`,
          position: 'absolute',
          borderRadius: `${this.borderRadius}px`
        }
      };
    });
  }

  public get maxX() {
    const containerBounds = this.getContainerBounds();
    return containerBounds.width - (Number(this.selectedItem.w) || 0);
  }

  public get maxY() {
    const containerBounds = this.getContainerBounds();
    return containerBounds.height - (Number(this.selectedItem.h) || 0);
  }

  public getContainerBounds() {
    if (this.$refs.container) {
      return (this.$refs.container as HTMLElement).getBoundingClientRect();
    }
    return { width: 0, height: 0 }; // Return some default bounds
  }

  public onPositionSelect($event: CustomEvent<string[]>) {
    this.position = $event.detail[0];

    this.deselectItem();

    switch (this.position) {
      case 'grid':
        this.positionItemsInGrid();
        break;
      case 'random':
        this.positionItemsRandomly();
        break;
    }
  }

  public positionItemsRandomly() {
    const activeElement = this.$refs.imageElement || this.$refs.canvasRef;

    if (!(activeElement instanceof HTMLElement)) {
      return;
    }

    const activeElementBounds = activeElement.getBoundingClientRect();

    // Get the width and height of the active element to constrain random placement
    const elementWidth = activeElementBounds.width;
    const elementHeight = activeElementBounds.height;

    this.localModelValue.forEach((item) => {
      let placed = false;
      let attempts = 0;

      while (!placed && attempts < 100) {
        // Limit attempts to avoid infinite loops
        const maxX = elementWidth - Number(item.w);
        const maxY = elementHeight - Number(item.h);

        const randomX = Math.random() * maxX;
        const randomY = Math.random() * maxY;

        // Check if the randomly chosen position overlaps with any already placed items
        let overlap = false;
        for (const otherItem of this.localModelValue) {
          if (otherItem === item) {
            continue; // Don't check against itself
          }
          if (this.isOverlapping(randomX, randomY, item, otherItem)) {
            overlap = true;
            break;
          }
        }

        if (!overlap) {
          item.x = Math.round(randomX);
          item.y = Math.round(randomY);
          placed = true;
        }

        attempts++;
      }
    });
  }

  private isOverlapping(x: number, y: number, item: PositionerItem, otherItem: PositionerItem): boolean {
    return (
      x < Number(otherItem.x) + Number(otherItem.w) &&
      x + Number(item.w) > otherItem.x &&
      y < Number(otherItem.y) + Number(otherItem.h) &&
      y + Number(item.h) > Number(otherItem.y)
    );
  }

  public positionItemsInGrid() {
    this.useSameSize = true;

    const itemsPerRow =
      this.localModelValue.length < this.maxColumnsPerRow ? this.localModelValue.length : this.maxColumnsPerRow;
    const verticalGap = Number(this.gridVerticalGap);
    const horizontalGap = Number(this.gridHorizontalGap);

    const activeElement = this.$refs.imageElement || this.$refs.canvasRef;

    if (!(activeElement instanceof HTMLElement)) {
      return;
    }

    const activeElementBounds = activeElement.getBoundingClientRect();

    if (this.localModelValue.length === 0) return; // Check if the array is empty

    // Set all items to the same width and height based on the first item
    const standardWidth = Number(this.localModelValue[0].w);
    const standardHeight = Number(this.localModelValue[0].h);

    // Calculate the total width and height of the grid
    const totalWidth = itemsPerRow * (standardWidth + horizontalGap) - horizontalGap;
    const totalRows = Math.ceil(this.localModelValue.length / itemsPerRow);
    const totalHeight = totalRows * (standardHeight + verticalGap) - verticalGap;

    // Calculate the offset to center the grid in the active element
    const offsetX = (activeElementBounds.width - totalWidth) / 2;
    const offsetY = (activeElementBounds.height - totalHeight) / 2;

    this.localModelValue.forEach((item, index) => {
      const row = Math.floor(index / itemsPerRow);
      const col = index % itemsPerRow;

      item.w = standardWidth;
      item.h = standardHeight;
      item.x = offsetX + (standardWidth + horizontalGap) * col;
      item.y = offsetY + (standardHeight + verticalGap) * row;
    });
  }
  public adjustItemPosition($event: MouseEvent) {
    // Determine the active element (image or canvas)
    const activeElement = this.$refs.imageElement || this.$refs.canvasRef;
    const parentElement = this.$refs.parentContainer;

    if (!(activeElement instanceof HTMLElement) || !(parentElement instanceof HTMLElement)) return;

    const activeElementBounds = activeElement.getBoundingClientRect();
    const parentElementBounds = parentElement.getBoundingClientRect();

    const maxLeft = parentElement.scrollLeft + parentElementBounds.width - Number(this.selectedItem.w);
    const maxTop = parentElement.scrollTop + activeElementBounds.height - Number(this.selectedItem.h);

    let left = Math.min(
      maxLeft,
      Math.max(
        parentElement.scrollLeft,
        $event.clientX - parentElementBounds.left - this.initialXOffset + parentElement.scrollLeft
      )
    );
    let top = Math.min(
      maxTop,
      Math.max(0, $event.clientY - activeElementBounds.top - this.initialYOffset + activeElement.scrollTop)
    );

    // Round to the nearest whole number
    left = Math.round(left);
    top = Math.round(top);

    this.selectedItem.x = left;
    this.selectedItem.y = top;
  }

  public onMoveMoveStart($event: MouseEvent, index: number) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dragging = true;
    this.selectItem(index);

    const positionerBounds = (this.$refs.container as HTMLElement).getBoundingClientRect();

    this.initialXOffset = $event.clientX - (positionerBounds.left + Number(this.selectedItem.x));
    this.initialYOffset = $event.clientY - (positionerBounds.top + Number(this.selectedItem.y));

    this.adjustItemPosition($event);

    document.addEventListener('mousemove', this.onMoveMove);
    document.addEventListener('mouseup', this.onMoveEnd);
  }

  public onMoveMove($event: MouseEvent) {
    if (!this.dragging || this.selectedItem === null) return;

    this.adjustItemPosition($event);
  }

  public save() {
    this.$emit('update:modelValue', this.localModelValue);
  }

  public cancel() {
    this.$emit('close-request');
    this.showPositionerModal = false;
  }

  public onMoveEnd() {
    this.dragging = false;
    // Remove global event listeners
    document.removeEventListener('mousemove', this.onMoveMove);
    document.removeEventListener('mouseup', this.onMoveEnd);
  }

  public onMouseResizeStart($event: MouseEvent, index: number) {
    $event.stopPropagation();
    $event.preventDefault();

    this.resizing = true;

    this.resizeStartX = $event.clientX;
    this.resizeStartY = $event.clientY;

    // Select the item to resize
    this.selectItem(index);

    document.addEventListener('mousemove', this.onMouseResize);
    document.addEventListener('mouseup', this.onResizeEnd);
  }

  public onMouseResize($event: MouseEvent) {
    if (!this.resizing || this.selectedItem === null) return;

    const dx = $event.clientX - this.resizeStartX;
    const dy = $event.clientY - this.resizeStartY;

    // Get container dimensions
    const containerBounds = (this.$refs.container as HTMLDivElement).getBoundingClientRect();

    // Calculate potential new dimensions
    let newWidth = Math.min(Number(this.selectedItem.w) + dx, containerBounds.width - Number(this.selectedItem.x));
    let newHeight = Math.min(Number(this.selectedItem.h) + dy, containerBounds.height - Number(this.selectedItem.y));

    // Make sure new dimensions are not smaller than a minimum size, e.g., 20x20
    newWidth = Math.max(20, newWidth);
    newHeight = Math.max(20, newHeight);

    // Set new dimensions
    this.selectedItem.w = newWidth;
    this.selectedItem.h = newHeight;

    // Update the initial mouse position for the next mousemove event
    this.resizeStartX = $event.clientX;
    this.resizeStartY = $event.clientY;
  }

  public onResizeEnd() {
    // Stop resizing and remove the event listeners
    this.resizing = false;
    document.removeEventListener('mousemove', this.onMouseResize);
    document.removeEventListener('mouseup', this.onResizeEnd);
  }

  selectItem(index: number) {
    this.selectedIndex = index;
    this.selectedItem = this.localModelValue[index];
  }

  deselectItem() {
    this.selectedIndex = null;
    this.selectedItem = { w: '', x: '', y: '', h: '', id: -1 };
  }

  clampInitialPosition(item: any) {
    const positionerBounds = (this.$refs.container as HTMLElement).getBoundingClientRect();
    const maxLeft = positionerBounds.width - item.w;
    const maxTop = positionerBounds.height - item.h;

    if (item.x > maxLeft || item.y > maxTop || item.x < 0 || item.y < 0) {
      // Set to top-left corner if out of bounds
      item.x = 0;
      item.y = 0;
    } else {
      // Otherwise, make sure it's inside the boundaries
      item.x = Math.min(maxLeft, Math.max(0, item.x));
      item.y = Math.min(maxTop, Math.max(0, item.y));
    }
  }

  clampAllInitialPositions() {
    this.localModelValue.forEach((item) => {
      this.clampInitialPosition(item);
    });
    this.localModelValue = [...this.localModelValue];
  }

  preloadImage(src: string) {
    const img = new Image();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    img.onload = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    img.onerror = () => {};
    img.src = src;
  }
  @Watch('x')
  @Watch('y')
  @Watch('w')
  @Watch('h')
  updateSelectedItem() {
    if (this.selectedIndex !== null) {
      this.localModelValue[this.selectedIndex] = {
        ...this.localModelValue[this.selectedIndex],
        ...this.selectedItem
      };
    }
  }

  @Watch('selectedItem.w')
  @Watch('selectedItem.h')
  updateItems() {
    if (this.selectedIndex !== null && this.useSameSize) {
      this.localModelValue = this.localModelValue.map((item) => ({
        ...item,
        w: this.selectedItem.w,
        h: this.selectedItem.h
      }));

      if (this.position === 'grid') {
        this.positionItemsInGrid();
      }
    }
  }

  @Watch('gridHorizontalGap')
  @Watch('gridVerticalGap')
  @Watch('maxColumnsPerRow')
  onGridValueChange() {
    if (this.position === 'grid') {
      this.positionItemsInGrid();
    }
  }

  @Watch('modelValue', { immediate: true })
  onModelValueChanged(newVal: PositionerItem[] | undefined) {
    if (newVal) {
      this.localModelValue = newVal.map((item) => {
        return { ...item };
      });
    }
  }

  @Watch('showPositionerModal')
  onShowPositionerModalChange(newVal: boolean) {
    if (newVal && this.modelValue) {
      this.localModelValue = this.modelValue?.map((item) => {
        return { ...item };
      });

      if (this.showingModal) return;

      setTimeout(() => {
        this.clampAllInitialPositions();
        this.showingModal = true;
      }, 500);
    }

    if (!newVal) {
      this.showingModal = false;
    }
  }

  @Watch('useSameSize')
  onUseSameSizeChanged(newVal: boolean) {
    if (newVal && this.selectedItem && this.selectedIndex !== null) {
      this.localModelValue = this.localModelValue.map((item) => ({
        ...item,
        w: this.selectedItem.w,
        h: this.selectedItem.h
      }));
    } else if (!newVal && this.selectedIndex !== null) {
      this.selectedItem = this.localModelValue[this.selectedIndex];
    }
  }

  private globalKeyDownHandler(event: KeyboardEvent) {
    if (this.selectedItem && this.showPositionerModal) {
      const step = 1;

      // Use the maxX and maxY getters for the bounds
      const maxLeft = this.maxX;
      const maxTop = this.maxY;

      switch (event.key) {
        case 'ArrowRight':
          this.selectedItem.x = Math.min(Number(this.selectedItem.x) + step, maxLeft);
          event.preventDefault();
          break;
        case 'ArrowLeft':
          this.selectedItem.x = Math.max(Number(this.selectedItem.x) - step, 0);
          event.preventDefault();
          break;
        case 'ArrowUp':
          this.selectedItem.y = Math.max(Number(this.selectedItem.y) - step, 0);
          event.preventDefault();
          break;
        case 'ArrowDown':
          this.selectedItem.y = Math.min(Number(this.selectedItem.y) + step, maxTop);
          event.preventDefault();
          break;
        default:
          // If it's any other key, we don't want to prevent the default behavior.
          break;
      }
    }
  }

  mounted() {
    window.addEventListener('keydown', this.globalKeyDownHandler);
    this.preloadImage(this.image);
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.globalKeyDownHandler);
  }
}
</script>

<style lang="scss" scoped>
.element-positioner {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;

  &__inputs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  &__container {
    background: #ccc;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    overflow: hidden;

    .positioner {
      padding-top: 90px;
      padding-bottom: 90px;
      text-align: center;
      width: auto;
      margin: 0 auto;
      overflow: auto;

      &__canvas {
        background-color: #dddddd;
      }

      img {
        vertical-align: top !important;
        min-width: 100%;
      }

      &__inner {
        position: relative;

        img {
          user-select: none;
          pointer-events: none;
        }
      }
      &__element {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: move;
        background-color: hsla(0, 0%, 8%, 0.75);
        border: 1px solid #ffffff;

        &--selected {
          outline: 2px solid rgba(50, 115, 220, 0.3);
        }

        &-label {
          min-width: 100px;
          max-width: 100%;
          width: 100%;
          display: inline-block;
          text-align: center;
          overflow-x: hidden;
          overflow-y: visible;
          vertical-align: middle;
          margin: 0;
          padding: 0.3rem 0.2rem;
          font-size: 1rem;
          text-shadow: none;
          background-color: rgba(231, 231, 231, 0.45);
          font-weight: 600;
          color: #000;
          line-height: 1.1;
          white-space: pre-wrap;
        }

        &-resize {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 20px;
          height: 20px;
          cursor: nw-resize;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .element-positioner {
    flex-direction: column-reverse;

    .form-element--size-small {
      width: 100%;
    }

    .positioner {
      overflow-x: scroll;
    }
  }
}
</style>
